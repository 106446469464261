<template>
  <b-container class="report-detail">
    <div class="px-3 mb-3">
      <b-row align-v="center" class="mt-2">
        <b-col cols="1" class="d-sm-none">
          <button-back
            :link="{ name: 'report', query: { from: from, to: to } }"
          ></button-back>
        </b-col>
        <b-col md="3" sm="12" cols="11" class="text-center text-light">
          <h5 class="d-inline-flex text-muted">
            {{ playerName }} ({{ total_sms }} tin)
          </h5>
        </b-col>
        <b-col md="3" sm="6" cols="7" class="mb-2">
          <date-range
            :from="from"
            :to="to"
            @date-change="dateChange"
            opens="right"
          ></date-range>
        </b-col>
        <b-col md="3" sm="6" cols="5" class="mb-2">
          <b-form-select size="sm" v-model="game">
            <b-form-select-option :value="-1">Tất cả</b-form-select-option>
            <b-form-select-option :value="1">Bắc</b-form-select-option>
            <b-form-select-option :value="2">Trung</b-form-select-option>
            <b-form-select-option :value="3">Nam</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="3" sm="12" cols="12" class="mb-2">
          <b-button
            size="sm"
            class="w-100"
            variant="primary"
            v-b-modal.modal-baoso
            >Báo Sổ</b-button
          >
        </b-col>
      </b-row>
    </div>
    <div v-if="report_show_type == 2">
      <report-detail-table
        @on-delete="confirmDeleteSms"
        @header-info="showHeaderInfo"
        @on-copy="onCopy"
      ></report-detail-table>
    </div>
    <div v-else>
      <report-detail-list
        @on-delete="confirmDeleteSms"
        @header-info="showHeaderInfo"
      ></report-detail-list>
    </div>

    <b-modal id="modal-baoso" ref="modal-baoso" hide-footer :title="titleBaoSo">
      <bao-so
        :playerId="this.$route.params.playerId"
        :from="this.from"
        :to="this.to"
        @title-bao-so="setTitleBaoSo"
        @hide-bao-so="hideBaoSo"
      >
      </bao-so>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DateRange from "@/components/DateRange";
import BaoSo from "./BaoSo";
import ReportDetailTable from "@/components/report/ReportDetailTable";
import ReportDetailList from "@/components/report/ReportDetailList";

export default {
  data() {
    return {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      game: -1,

      total_sms: 0,
      playerName: "",

      titleBaoSo: "",
      playerPercent: 0,

      report_show_type: 2
    };
  },
  components: {
    "bao-so": BaoSo,
    "date-range": DateRange,
    "report-detail-table": ReportDetailTable,
    "report-detail-list": ReportDetailList
  },
  watch: {
    game: async function(nVal) {
      await this.$nextTick();

      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          game: nVal
        }
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "userSetting"])
  },
  methods: {
    hideBaoSo() {
      setTimeout(() => {
        this.$refs["modal-baoso"].hide();
      }, 1000);
    },
    setTitleBaoSo(title) {
      this.titleBaoSo = title;
    },
    confirmDeleteSms(id) {
      let vm = this;
      vm.$bvModal
        .msgBoxConfirm(
          "Việc xóa tin là không thể khôi phục, bạn có chắc sẽ xóa",
          {
            title: "Xác nhận xóa sms",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Xóa",
            cancelTitle: "Không",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(async value => {
          if (value) {
            let res = await this.$http.delete("bets/" + id);
            if (res.data.code == 0) {
              window.location.reload();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async dateChange(date) {
      this.from = date.from;
      this.to = date.to;

      await this.$nextTick();

      if (
        this.$router.history.current.query.from == this.from &&
        this.$router.history.current.query.to == this.to
      )
        return;

      this.game = -1;
      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          from: this.from,
          to: this.to,
          game: -1
        }
      });
    },
    showHeaderInfo(datas) {
      this.playerName = datas.playerName;
      this.total_sms = datas.total_sms;
    },
    onCopy(sms) {
      let vm = this;
      this.$copyText(sms).then(
        function() {
          vm.$bvToast.toast("Thành công", {
            title: "Copy",
            variant: "success",
            solid: true,
            toaster: "b-toaster-bottom-center"
          });
        },
        function() {
          vm.$bvToast.toast("Không thành công", {
            title: "Copy",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center"
          });
        }
      );
    }
  },
  created() {
    this.report_show_type =
      this.userSetting && this.userSetting.report_show_type
        ? this.userSetting.report_show_type
        : 2;

    this.from = this.$route.query.from
      ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.to = this.$route.query.to
      ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    this.game = this.$route.query.game ? this.$route.query.game : -1;
  }
};
</script>
<style>
.report-detail .table th,
.report-detail .table td {
  border-top: none;
}

.report-detail .table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
.report-detail .table tfoot th {
  border-top: 1px solid #dee2e6 !important;
}

/* .report-detail .sms-content:nth-child(even){
  background-color: #f1f1f1;
} */
.report-detail .sms {
  white-space: pre-wrap !important;
  text-align: left;
  word-wrap: break-word;
}
.report-detail .title-sms {
  text-align: left;
}
.report-detail hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5px;
}
.report-detail .delete-sms {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-top: 0px;
  margin-right: 6px;
}
</style>
