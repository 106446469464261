<template>
  <div class="content">
    <xac-trung
      class="px-3 pt-3 content-summary card-border-top"
      :data="summary"
      :winloss="thu_tra"
      :showAmount="true"
      :isShowCheckSwitchXac="false"
      :playerPercent="playerPercent"
    >
    </xac-trung>
    <div class="sms-content" v-for="(item, idx) in datas" :key="idx">
      <div class="d-flex w-100 justify-content-between title-sms px-3">
        <router-link
          :to="{
            name: 'betSms',
            query: {
              smsid: item.id,
              origin: 'reportPlayerDetail',
              from: from,
              to: to
            }
          }"
          class="sms"
        >
          {{ idx + 1 }}) {{ getGameCode(item.game_id) }} -
          {{
            item.sms.length > 75
              ? item.sms.substring(0, 75).replace(/(\r\n|\n|\r)/gm, "") + " ..."
              : item.sms.replace(/(\r\n|\n|\r)/gm, "")
          }}
        </router-link>
        <b-button
          variant="outline-danger"
          class="delete-sms"
          size="sm"
          @click="$emit('on-delete', item.id)"
          >Xóa</b-button
        >
      </div>
      <xac-trung
        class="card-border-bottom text-left px-3"
        :data="item.detail"
        :winloss="item.winloss"
        :bet_time="item.bet_time"
        :number_win="item.number_win"
        :showAmount="true"
        :isShowCheckSwitchXac="false"
      >
      </xac-trung>
      <hr />
    </div>
    <infinite-loading :identifier="infiniteId" @infinite="fetchPlayerDetail">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import moment from "moment";
import XacTrung from "@/components/XacTrung";
import InfiniteLoading from "vue-infinite-loading";
import { Utilities } from "@/utilities";

export default {
  data() {
    return {
      datas: [],
      summary: [],
      thu_tra: 0,
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      game: -1,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      playerPercent: 0,
      infiniteId: +new Date()
    };
  },
  components: {
    XacTrung,
    InfiniteLoading
  },
  created() {
    this.bindData();
    this.resetPage();
  },
  watch: {
    $route() {
      this.bindData();
      this.resetPage();
    },
    currentPage: function(nVal) {
      this.currentPage = nVal;
      this.resetPage();
    },
    perPage: function(nVal) {
      this.perPage = nVal;
      this.resetPage();
    }
  },
  methods: {
    async fetchPlayerDetail($state) {
      let pid = this.$route.params.playerId;

      let params = {
        from: this.from,
        to: this.to,
        page: this.page,
        game: this.game
      };
      if (this.page == 1) params.summary = 1;

      let res = await this.$http.get("report/player/" + pid, {
        params: params
      });
      if (res.data.code == 0) {
        if (res.data.data.rp.length == 0) {
          $state.complete();
          return;
        }

        this.playerName = res.data.data.player;
        this.playerPercent = res.data.data.percent;

        if (res.data.data.summary) {
          let total = {
            "2c-dd": {
              turn_over: res.data.data.summary.to_2cdd,
              turn_over_point: res.data.data.summary.top_2cdd,
              amount: res.data.data.summary.a_2cdd,
              win: res.data.data.summary.w_2cdd,
              win_point: res.data.data.summary.wp_2cdd
            },
            "2c-b": {
              turn_over: res.data.data.summary.to_2cb,
              turn_over_point: res.data.data.summary.top_2cb,
              amount: res.data.data.summary.a_2cb,
              win: res.data.data.summary.w_2cb,
              win_point: res.data.data.summary.wp_2cb
            },
            "3c": {
              turn_over: res.data.data.summary.to_3c,
              turn_over_point: res.data.data.summary.top_3c,
              amount: res.data.data.summary.a_3c,
              win: res.data.data.summary.w_3c,
              win_point: res.data.data.summary.wp_3c
            },
            "4c": {
              turn_over: res.data.data.summary.to_4c,
              turn_over_point: res.data.data.summary.top_4c,
              amount: res.data.data.summary.a_4c,
              win: res.data.data.summary.w_4c,
              win_point: res.data.data.summary.wp_4c
            },
            dat: {
              turn_over: res.data.data.summary.to_da,
              turn_over_point: res.data.data.summary.top_da,
              amount: res.data.data.summary.a_da,
              win: res.data.data.summary.w_da,
              win_point: res.data.data.summary.wp_da
            },
            dax: {
              turn_over: res.data.data.summary.to_dx,
              turn_over_point: res.data.data.summary.top_dx,
              amount: res.data.data.summary.a_dx,
              win: res.data.data.summary.w_dx,
              win_point: res.data.data.summary.wp_dx
            }
          };
          this.summary = total;
          this.thu_tra = res.data.data.summary.winloss;
          this.total_sms = res.data.data.summary.total_sms;
        }

        let d = res.data.data.rp.map(function(e) {
          return {
            id: e.id,
            sms: e.sms,
            game_id: e.game_id,
            number_win: e.number_win,
            winloss: e.winloss,
            bet_time: moment(e.bet_time.split(" ")[0], "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            ),
            detail: {
              "2c-dd": {
                turn_over: e.to_2cdd,
                turn_over_point: e.top_2cb,
                amount: e.a_2cdd,
                win: e.w_2cdd,
                win_point: e.wp_2cdd
              },
              "2c-b": {
                turn_over: e.to_2cb,
                turn_over_point: e.top_2cb,
                amount: e.a_2cb,
                win: e.w_2cb,
                win_point: e.wp_2cb
              },
              "3c": {
                turn_over: e.to_3c,
                turn_over_point: e.top_3c,
                amount: e.a_3c,
                win: e.w_3c,
                win_point: e.wp_3c
              },
              "4c": {
                turn_over: e.to_4c,
                turn_over_point: e.top_4c,
                amount: e.a_4c,
                win: e.w_4c,
                win_point: e.wp_4c
              },
              dat: {
                turn_over: e.to_da,
                turn_over_point: e.top_da,
                amount: e.a_da,
                win: e.w_da,
                win_point: e.wp_da
              },
              dax: {
                turn_over: e.to_dx,
                turn_over_point: e.top_dx,
                amount: e.a_dx,
                win: e.w_dx,
                win_point: e.wp_dx
              }
            }
          };
        });
        let data = [...this.datas, ...d];
        this.datas = data;
        this.page++;

        if (res.data.data.rp.length < this.perPage) {
          $state.complete();
        } else {
          $state.loaded();
        }

        this.$emit("header-info", {
          playerName: this.playerName,
          total_sms: this.total_sms
        });
      }
    },
    bindData() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.game = this.$route.query.game ? this.$route.query.game : -1;
    },
    resetPage() {
      this.page = 1;
      this.datas = [];
      this.summary = {};
      this.thu_tra = 0;
      this.infiniteId++;
    },
    formatMoney(money) {
      return Utilities.currencyReport(money);
    },
    formatPoint(point) {
      return Utilities.thousandFormat(point);
    },
    getGameCode(game_id) {
      switch (game_id) {
        case 1:
          return "MB";
        case 2:
          return "MT";
        case 3:
          return "MN";
      }
    }
  }
};
</script>
<style>
.report-detail .table th,
.report-detail .table td {
  border-top: none;
}

.report-detail .table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
.report-detail .table tfoot th {
  border-top: 1px solid #dee2e6 !important;
}

/* .report-detail .sms-content:nth-child(even){
  background-color: #f1f1f1;
} */
.report-detail .sms {
  white-space: pre-wrap !important;
  text-align: left;
  word-wrap: break-word;
}
.report-detail .title-sms {
  text-align: left;
}
.report-detail hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5px;
}
.report-detail .delete-sms {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-top: 0px;
  margin-right: 6px;
}
</style>
