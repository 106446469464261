<template>
  <div style="min-height: 1900px;">
    <b-table
      class="report-table report-detail-table text-left"
      responsive="false"
      :items="datas"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      foot-clone
    >
      <template #head()="scope">
        <div class="text-nowrap">{{ scope.label }}</div>
      </template>

      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(sms)="row">
        <router-link
          :to="{
            name: 'betSms',
            query: {
              smsid: row.item.id,
              origin: 'reportPlayerDetail',
              from: from,
              to: to
            }
          }"
          class="sms"
          >{{
            row.item.sms.length > 10
              ? row.item.sms
                  .substring(0, 10)
                  .replace(/(\r\n|\n|\r)/gm, "")
                  .trim() + "..."
              : row.item.sms.replace(/(\r\n|\n|\r)/gm, "").trim()
          }}</router-link
        >
      </template>

      <template #cell(d2)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(d3)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(d4)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(da)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(dx)="data">
        <span v-html="data.value"></span>
      </template>

      <template #cell(winloss)="data">
        <span v-html="data.value"></span>
      </template>
      <template #cell(number_win)="data">
        <span style="min-width: 130px" v-html="data.value"></span>
      </template>

      <template #cell(actions)="row">
        <b-button-group>
          <b-button size="sm" @click="$emit('on-copy', row.item.sms)">
            <b-icon icon="files" font-scale="1"></b-icon>
          </b-button>
          <b-button size="sm" @click="$emit('on-delete', row.item.id)">
            <b-icon icon="trash" font-scale="1"></b-icon>
          </b-button>
        </b-button-group>
      </template>

      <template #foot(stt)><span></span></template>
      <template #foot(bet_time)><span></span></template>
      <template #foot(sms)>
        <span>Tổng:</span>
      </template>
      <template #foot(d2)
        ><span>{{ format(summary.d2) }}</span></template
      >
      <template #foot(d3)
        ><span>{{ format(summary.d3) }}</span></template
      >
      <template #foot(d4)
        ><span>{{ format(summary.d4) }}</span></template
      >
      <template #foot(da)
        ><span>{{ format(summary.da) }}</span></template
      >
      <template #foot(dx)
        ><span>{{ format(summary.dx) }}</span></template
      >
      <template #foot(win)
        ><span>{{ format(summary.win) }}</span></template
      >
      <template #foot(winloss)>
        <span :class="summary.winloss < 0 ? 'text-danger' : 'text-primary'">{{
          format(summary.winloss)
        }}</span>
      </template>

      <template #foot(win_point)><span></span></template>
      <template #foot(number_win)><span></span></template>
      <template #foot(percent)><span></span></template>
      <template #foot(actions)><span></span></template>
    </b-table>
    <b-row>
      <b-col cols="3" class="text-left">
        <b-form-select
          v-model="perPage"
          :options="optionPerpage"
        ></b-form-select>
      </b-col>
      <b-col cols="9" class="text-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { Utilities } from "@/utilities";
import moment from "moment";

export default {
  name: "report-detail-table",
  data() {
    return {
      datas: [],
      summary: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      game: -1,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      playerPercent: 0,
      fields: [
        "stt",
        {
          key: "bet_time",
          label: "Ngày sổ",
          sortable: true
        },
        {
          key: "sms",
          label: "Tin nhắn",
          sortable: true
        },
        {
          key: "d2",
          label: "2D",
          sortable: true,
          formatter: "turnOver"
        },
        {
          key: "d3",
          label: "3D",
          sortable: true,
          formatter: "turnOver"
        },
        {
          key: "d4",
          label: "4D",
          sortable: true,
          formatter: "turnOver"
        },
        {
          key: "da",
          label: "Đá",
          sortable: true,
          formatter: "turnOver"
        },
        {
          key: "dx",
          label: "Đá xiên",
          sortable: true,
          formatter: "turnOver"
        },
        {
          key: "win",
          label: "Tiền trúng",
          sortable: true,
          formatter: "format"
        },
        {
          key: "winloss",
          label: "Thắng/Thua",
          sortable: true,
          formatter: "formatWinloss"
        },
        {
          key: "percent",
          label: "Theo",
          sortable: true,
          formatter: (value, key, item) => {
            return this.playerPercent == 0
              ? ""
              : this.formatWinloss(
                  (item.winloss * this.playerPercent) / 100.0
                ) +
                  " (" +
                  this.playerPercent +
                  "%)";
          }
        },
        {
          key: "win_point",
          label: "Trúng",
          sortable: false
        },
        {
          key: "number_win",
          label: "Số trúng",
          sortable: false,
          class: "number-win"
        },
        { key: "actions", label: "Action", sortable: true }
      ],
      optionPerpage: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ]
    };
  },
  created() {
    this.bindData();
    this.fetchPlayerDetail();
  },
  watch: {
    $route() {
      this.bindData();
      this.fetchPlayerDetail();
    },
    currentPage: function(nVal) {
      this.currentPage = nVal;
      this.fetchPlayerDetail();
    },
    perPage: function(nVal) {
      this.perPage = nVal;
      this.fetchPlayerDetail();
    }
  },
  methods: {
    async fetchPlayerDetail() {
      let pid = this.$route.params.playerId;

      let params = {
        from: this.from,
        to: this.to,
        page: this.currentPage,
        game: this.game,
        perpage: this.perPage
      };
      if (this.page == 1) params.summary = 1;

      let res = await this.$http.get("report/player/" + pid, {
        params: params
      });
      if (res.data.code == 0) {
        this.playerName = res.data.data.player;
        this.playerPercent = res.data.data.percent;

        if (res.data.data.summary) {
          this.summary = {
            d2: res.data.data.summary.to_2cdd + res.data.data.summary.to_2cb,
            d3: res.data.data.summary.to_3c,
            d4: res.data.data.summary.to_4c,
            da: res.data.data.summary.to_da,
            dx: res.data.data.summary.to_dx,
            win:
              res.data.data.summary.w_2cb +
              res.data.data.summary.w_2cdd +
              res.data.data.summary.w_3c +
              res.data.data.summary.w_4c +
              res.data.data.summary.w_da +
              res.data.data.summary.w_dx,
            winloss: res.data.data.summary.winloss
          };
          this.total_sms = res.data.data.summary.total_sms;
        }

        this.datas = res.data.data.rp.map(function(e) {
          let win_by_type = "";
          if (e.wp_2cdd + e.wp_2cb > 0)
            win_by_type += "2d:" + (e.wp_2cdd + e.wp_2cb) + " ";
          if (e.wp_3c > 0) win_by_type += "3d:" + e.wp_3c + " ";
          if (e.wp_4c > 0) win_by_type += "4d:" + e.wp_4c + " ";
          if (e.wp_da > 0) win_by_type += "da:" + e.wp_da + " ";
          if (e.wp_dx > 0) win_by_type += "dx:" + e.wp_dx + " ";

          // let win_point = {};
          // if (e.number_win != "") {
          //   e.number_win.split(" ").map(function (e) {
          //     let type = e.substring(0, 2);
          //     let point = e.split("_")[e.split("_").length - 1].slice(0, -1);
          //     point = parseInt(point);
          //     if (win_point[type]) {
          //       win_point[type] = win_point[type] + point;
          //     } else {
          //       win_point[type] = point;
          //     }
          //   });
          //   win_point = Object.entries(win_point).reduce((str, [p, val]) => {
          //     return `${str}${p}:${val} `;
          //   }, "");
          // } else {
          //   win_point = "";
          // }

          return {
            id: e.id,
            sms: e.sms,
            number_win: e.number_win.replaceAll(" ", "<br/>"), //so trung
            // win_point: win_point, //trung
            win_point: win_by_type, //trung
            winloss: e.winloss, //thang/thua
            bet_time: moment(e.bet_time.split(" ")[0], "YYYY-MM-DD").format(
              "DD/MM/YY"
            ),
            win: e.w_2cb + e.w_2cdd + e.w_3c + e.w_4c + e.w_da + e.w_dx, //tien trung
            // win_by_type: win_by_type, //trung 2d:30n

            d2: {
              turn_over: e.to_2cdd + e.to_2cb,
              turn_over_point: e.top_2cdd + e.top_2cb
            },
            d3: {
              turn_over: e.to_3c,
              turn_over_point: e.top_3c
            },
            d4: {
              turn_over: e.to_4c,
              turn_over_point: e.top_4c
            },
            da: {
              turn_over: e.to_da,
              turn_over_point: e.top_da
            },
            dx: {
              turn_over: e.to_dx,
              turn_over_point: e.top_dx
            }
          };
        });

        this.currentPage = res.data.data.page;
        this.perPage = res.data.data.item_per_page;
        this.totalRows = res.data.data.item_per_page * res.data.data.total_page;

        this.$emit("header-info", {
          playerName: this.playerName,
          total_sms: this.total_sms
        });
      }
    },
    bindData() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.game = this.$route.query.game ? this.$route.query.game : -1;
    },
    format(money) {
      return Utilities.currencyReport(money);
    },
    formatWinloss(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class=' " +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    },
    turnOver(money) {
      let m =
        "<span>" + Utilities.currencyReport(money.turn_over) + "</span><br />";
      if (money.turn_over_point > 0) {
        m += "<small>(" + money.turn_over_point + "n)</small>";
      }
      return m;
    }
  }
};
</script>
<style>
.report-detail-table .table.b-table > thead > tr > [aria-sort="none"],
.report-detail-table .table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: none;
}
.report-detail-table .table th,
.report-detail-table .table td {
  border-bottom: none;
  border-top: none;
}
.number-win {
  min-width: 130px;
}
.sms {
  min-width: 115px;
}
/* .report-detail-table .table th,
.report-detail-table .table td {
  white-space: nowrap;
} */
</style>
