var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('xac-trung',{staticClass:"px-3 pt-3 content-summary card-border-top",attrs:{"data":_vm.summary,"winloss":_vm.thu_tra,"showAmount":true,"isShowCheckSwitchXac":false,"playerPercent":_vm.playerPercent}}),_vm._l((_vm.datas),function(item,idx){return _c('div',{key:idx,staticClass:"sms-content"},[_c('div',{staticClass:"d-flex w-100 justify-content-between title-sms px-3"},[_c('router-link',{staticClass:"sms",attrs:{"to":{
          name: 'betSms',
          query: {
            smsid: item.id,
            origin: 'reportPlayerDetail',
            from: _vm.from,
            to: _vm.to
          }
        }}},[_vm._v(" "+_vm._s(idx + 1)+") "+_vm._s(_vm.getGameCode(item.game_id))+" - "+_vm._s(item.sms.length > 75 ? item.sms.substring(0, 75).replace(/(\r\n|\n|\r)/gm, "") + " ..." : item.sms.replace(/(\r\n|\n|\r)/gm, ""))+" ")]),_c('b-button',{staticClass:"delete-sms",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.$emit('on-delete', item.id)}}},[_vm._v("Xóa")])],1),_c('xac-trung',{staticClass:"card-border-bottom text-left px-3",attrs:{"data":item.detail,"winloss":item.winloss,"bet_time":item.bet_time,"number_win":item.number_win,"showAmount":true,"isShowCheckSwitchXac":false}}),_c('hr')],1)}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.fetchPlayerDetail}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }